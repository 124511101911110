/*!
 * Video & Audio helper for fancyBox
 * version: 1.0.1 (Thu, 27 Mar 2014)
 * @requires fancyBox v2.0 or later
 *
 * Usage:
 *     $(".fancybox").fancybox({
 *         helpers : {
 *             videoaudiojs: true
 *         }
 *     });
*/
var videoObject, audioObject;

(function ($) {
	"use strict";

	//Add helper object
	$.fancybox.helpers.videoaudio = {
		defaults : {
			audiojs: {
				matcher : /(mp3)/i,
				type: 'html',
				types: {
					'mp3': 'audio/mpeg'
				},
				mediatype: 'audio',
				html: '<div class="audiocontainer">{cover}<audio autoplay controls><source src="{href}" type="{type}" /></audio></div>'
			},
			videojs: {
				matcher : /(mp4)/i,
				type: 'html',
				types: {
					'mp4': 'video/mp4'
				},
				mediatype: 'video',
				html: '<div class="videocontainer" style="{width}{height}"><video autoplay controls{poster}><source src="{href}" type="{type}" /></video></div>'
			}
		},

		beforeLoad: function(opts, obj) {
			var url   = obj.href || '',
				type  = false,
				content = '',
				what,
				item,
				rez;

			for (what in opts) {
				if (opts.hasOwnProperty(what)) {
					item = opts[ what ];
					rez  = url.match( item.matcher );

					if (rez) {

						var parts = url.split('.');
						var extension = parts[ parts.length - 1 ].toLowerCase();

						var cover, poster, width, height = '';
						cover = poster = (typeof obj.element != 'undefined') ? obj.element.data('cover') : ( typeof obj.cover != 'undefined' && obj.cover != '' ? obj.cover : '');
						
						if(cover != '' && typeof cover != 'undefined')
							cover = '<img src="' + cover + '" />';
						else
							cover = '';

						if(poster != '' && typeof poster != 'undefined')
							poster = ' poster="' + poster + '"';
						else
							poster = '';

						if(obj.element.attr('data-width'))
							width = 'width:' + parseInt(obj.element.attr('data-width')) + 'px;';
						if(obj.element.attr('data-height'))
							height = 'height:' + parseInt(obj.element.attr('data-height')) + 'px;';

						obj.content = item.html
							.replace('{href}', url)
							.replace('{type}', item.types[extension])
							.replace('{cover}', cover)
							.replace('{poster}', poster)
							.replace('{width}', width)
							.replace('{height}', height);

						obj.type = item.type;
						obj.mediatype = item.mediatype;

						break;
					}
				}
			}
		},
		beforeShow: function(opts, obj)
		{
			var url = obj.href || '',
				what,
				item,
				rez;

			for (what in opts) {
				if (opts.hasOwnProperty(what)) {
					item = opts[ what ];
					rez  = url.match( item.matcher );

					if (rez) 
					{
						obj.wrap.on('media.play', function()
						{
							var player = $(this).find('video, audio').get(0);
							player.play();
						});

						obj.wrap.on('media.pause', function()
						{
							var player = $(this).find('video, audio').get(0);
							player.pause();
						});

						var player = obj.wrap.find('video, audio');
						player.on('ended', function()
						{
							obj.wrap.trigger('media.ended');
						});

						break;
					}
				}
			}
		}
	};

}(jQuery));