(function( $ ){

	var ajaxContent = function() {
		
		var me = this;
		var el, $el;
		var busy = false;
		
		this.settings = {
			'url': '',
			'tabs': 'ul.tabs a',
			'search': 'input[type="search"]',
			'container': 'ul.list',
			'tmpl': {
				'simple': '',
				'extended': ''
			},
			'extendedItems': 9,
			'items': [],
			'parameters': {
				'image': '100-100'
			}
		}
		
		this.init = function( options ) 
		{
			$.extend(true, me.settings, options, $(this).data());
			me.el = this;
			me.$el = $(this);
			me.categories = [];

			me.$el.container = $(me.settings.container, $(this));
			me.$el.search = $(me.settings.search, $(this));

			me.renderItems( me.settings.items );

			me.$el.on('click', me.settings.tabs, me.clickCategory);
			me.$el.on('keyup', $(me.settings.search), me.fetch);
		}

		/**
		 * Render the items in the container
		 *
		 * @param  array items
		 * @return void
		 */
		this.renderItems = function( items )
		{
			me.$el.container.empty();

			for(var i in items)
			{
				var template = ((parseInt(i)+1) <= me.settings.extendedItems) ? 'extended' : 'simple';

				$.tmpl( $(me.settings.tmpl[template]), items[i] ).appendTo( me.$el.container );
			}

			me.$el.container.removeClass('loading');
		}

		/**
		 * Switch category
		 *
		 * @param e jquery Event
		 * @return void
		 */
		this.clickCategory = function(e)
		{
			e.preventDefault();

			me.categories = [];
			
			if(typeof $(this).data('category') != 'undefined')
				me.categories.push( parseInt($(this).data('category')) );

			$(this).addClass('active').closest('li').addClass('active')
				.siblings('.active').removeClass('active')
					.find('a').removeClass('active');

			me.fetch();
		}

		/**
		 * Fetch new documents
		 *
		 * @return void
		 */
		this.fetch = function()
		{
			me.$el.container.addClass('loading');

			if(typeof me.fetchRequest != 'undefined') me.fetchRequest.abort();

			var params = $.extend(me.settings.parameters, {
				'q': me.$el.search.val(),
				'categories': me.categories
			});

			me.fetchRequest = $.getJSON(me.settings.url, params, function(items)
			{
				me.renderItems( items );
			});
		}
		
	}
	
	$.fn.ajaxContent = function( options ) 
	{
		return this.each(function() 
		{
			var newAjaxContent = new ajaxContent();
			newAjaxContent.init.call(this, options);
			$(this).data( 'ajaxContent', newAjaxContent );
		});
	};

})( jQuery );